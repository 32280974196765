import React from "react";
import Layout from '../../components/layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
            <h1>Thank you for reaching out.</h1>
            <p>Samuel Andres will respond to your message as soon as possible.</p>
        </div>
      </div>
      </section>
  </Layout>
);